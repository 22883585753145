import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import SEO from 'src/components/seo'
import ThankYouMobileUpsell from 'src/components/ThankYouMobileUpsell'

import { breakpoints, ContentContainer, BigHeader, SmallText } from 'src/utils/styles/global-styles'

import Analytics from 'src/utils/Analytics'
import QueryParams from 'src/utils/QueryParams'
import { darken, lighten } from 'polished'
import { BtnGroup, BadgeAnchorCtn } from 'src/components/ThankYouMobileUpsell/styles'
import { IS_FOREIGN_COUNTRY, COUNTRIES } from '../../utils/constants'

const enquireKey = process.env.GATSBY_SHOPIFY_ENQUIRE_KEY
const defaultLanguage = process.env.GATSBY_I18N_DEFAULT_LANGUAGE

const extractProductsAndIframe = (urlParams) => {
  const gaProducts = []
  let i = 0
  let numFrames = 0
  const frameSize = { size: [] }
  const calendarSize = { size: [] }
  const calendarStyle = { style: [] }

  const frameColors = { colors: [] }
  let numCals = 0

  let numTVs = 0
  let numPhotobooks = 0
  let numPhotoBookPlus = 0
  let kind = ''
  let trial = ''

  while (urlParams[`lineItems[${i}][index]`]) {
    const itemIndex = parseInt(urlParams[`lineItems[${i}][index]`])
    const sku = urlParams[`lineItems[${i}][sku]`]
    const price = urlParams[`lineItems[${i}][price]`]
    const quantity = urlParams[`lineItems[${i}][quantity]`]
    const title = urlParams[`lineItems[${i}][title]`]

    const intQuantity = parseInt(quantity)
    if (title.indexOf('Skylight Calendar') !== -1) {
      numCals += intQuantity

      if (title.indexOf('15') !== -1 && calendarSize.size.indexOf('15 in') === -1) {
        calendarSize.size.push('15 in')
        calendarStyle.style.push('white')
      } else if (
        title.indexOf('Calendar Max') !== -1 &&
        calendarSize.size.indexOf('27 in') === -1
      ) {
        calendarSize.size.push('27 in')
        if (title.indexOf('Charcoal') !== -1) {
          calendarStyle.style.push('shadowbox-charcoal')
        } else if (title.indexOf('White') !== -1) {
          calendarStyle.style.push('classic-white')
        } else if (title.indexOf('Black') !== -1) {
          calendarStyle.style.push('classic-black')
        } else if (title.indexOf('Natural Aluminum') !== -1) {
          calendarStyle.style.push('shadowbox-aluminum')
        }
      } else if (
        (title === 'The Skylight Calendar' || title === 'The Skylight Calendar with Plus Plan') &&
        calendarSize.size.indexOf('10 in') === -1
      ) {
        calendarSize.size.push('10 in')
        calendarStyle.style.push('white')
      }
    }
    const isSkylightFrame = title.includes('Frame')

    if (isSkylightFrame) {
      numFrames += intQuantity
      if (title.includes('Silver')) {
        frameColors.colors.push('Silver')
      }
      if (title.includes('White')) {
        frameColors.colors.push('White')
      }
      if (title.includes('Gold')) {
        frameColors.colors.push('Gold')
      }
      if (title.includes('Poppy')) {
        frameColors.colors.push('Poppy')
      }
      if (
        title === 'The Skylight Frame' ||
        title.includes('15') ||
        title === 'The Skylight Frame with Plus'
      ) {
        frameColors.colors.push('Black')
      }
      if (title.includes('15')) {
        frameSize.size.push('15 in')
      }
      if (title.includes('8')) {
        frameSize.size.push('8 in')
      }
      if (
        title.includes('Silver') ||
        title.includes('White') ||
        title.includes('Gold') ||
        title.includes('Poppy') ||
        title === 'The Skylight Frame' ||
        title === 'The Skylight Frame with Plus'
      ) {
        frameSize.size.push('10 in')
      }
    }
    if (title.indexOf('TV') !== -1) numTVs += intQuantity
    if (title.indexOf('Digital') !== -1) numTVs += intQuantity
    if (title.indexOf('Yearly Photo Book Subscription') !== -1) numPhotobooks += intQuantity
    if (title.indexOf('Photo Book Plus') !== -1) {
      numPhotoBookPlus += intQuantity
    }
    if (title.indexOf('Plus') !== -1) {
      if (
        title.indexOf('Calendar Plus') !== -1 ||
        title.indexOf('with Plus Plan') !== -1 ||
        // checking for free trial variant
        /Skylight Plus \(\$\d{1,2}?\.?\d{2}\/(mo|yr)\) w\/ Free Trial/.test(title)
      ) {
        kind = 'Calendar Plus'
        // Free Trial variant trial parsing
        if (title.indexOf('Trial') !== -1) {
          const [priceFromTitle, cadence] = title.match(/\d{1,2}?\.?\d{2}\/(mo|yr)/)[0]?.split('/')
          if (!priceFromTitle || !cadence) return ''
          trial = `2wk/${priceFromTitle}/${cadence}`
        }
        // -- End of Free Trial variant trial parsing
      } else kind = 'Frame Plus'
    }
    gaProducts.push({
      id: sku,
      name: title,
      price,
      quantity: intQuantity,
    })
    i += 1
  }

  const onlyPlus = urlParams.totalQuantity === '0' && !numTVs

  return {
    gaProducts,
    numFrames,
    numCals,
    numTVs,
    numPhotobooks,
    numPhotoBookPlus,
    onlyPlus,
    frameSize,
    frameColors,
    calendarSize,
    calendarStyle,
    kind,
    trial,
  }
}

class ThankYou extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,
      showPixels: false,
      anyTVs: false,
    }
  }

  componentDidMount() {
    const urlParams = QueryParams.from(window)
    const { t } = this.props

    if (urlParams.orderNumber) {
      const { email, onlyFrame, purchasedPlus, orderNumber, orderID } = urlParams
      const onlyPlus = urlParams.totalQuantity === '0'
      const {
        gaProducts,
        numCals,
        numFrames,
        numTVs,
        numPhotobooks,
        numPhotoBookPlus,
        frameSize,
        frameColors,
        calendarSize,
        calendarStyle,
        kind,
        trial,
      } = extractProductsAndIframe(urlParams)

      // DEPRECATED:
      window.RECHARGE_URL_PARAMS = {
        ...urlParams,
        onlyPlus,
        numCals,
        numFrames,
        numTVs,
        frameSize,
        frameColors,
        calendarSize,
        calendarStyle,
        kind,
        trial,
      }
      // IN FAVOR OF:
      window.GTM_PARAMS = {
        ...urlParams,
        onlyPlus,
        numCals,
        numFrames,
        numTVs,
        frameSize,
        frameColors,
        calendarSize,
        calendarStyle,
        kind,
        trial,
      }
      const newState = {
        email,
        onlyPlus,
        showPixels: true,
        anyTVs: numTVs > 0,
        shareASaleSrc: `https://www.shareasale.com/sale.cfm?tracking=${urlParams.orderNumber}&amount=${urlParams.subtotal}&merchantID=68125&transtype=sale&newcustomer=${urlParams.isNewCustomer}&currency=${COUNTRIES[defaultLanguage].currency}&xtype=${COUNTRIES[defaultLanguage].abv}`,
      }

      const trackedKey = `tracked:${urlParams.orderNumber}`
      const alreadyTracked = localStorage.getItem(trackedKey)
      const currency = IS_FOREIGN_COUNTRY ? t('USD') ?? 'USD' : 'USD'
      if (!alreadyTracked) {
        localStorage.setItem(trackedKey, 'true')
        const purchaseValue = parseFloat(urlParams.subtotal).toFixed(2)
        // Give GTM time to load all the necessary libraries.
        setTimeout(() => {
          Analytics.push({
            event: 'Purchased',
            purchaseValue,
            orderNumber,
            ecommerce: {
              currency,
              id: urlParams.orderNumber,
              affiliation: onlyFrame ? 'Shopify' : 'ReCharge',
              revenue: purchaseValue.toString(),
              transaction_id: urlParams.orderNumber,
              value: purchaseValue.toString(),
              items: gaProducts,
            },
            products: gaProducts,
          })
          Analytics.push({
            event: 'purchase',
            ecommerce: {
              currency,
              transaction_id: urlParams.orderNumber,
              affiliation: onlyFrame ? 'Shopify' : 'ReCharge',
              value: purchaseValue,
              items: gaProducts,
            },
          })
          if (purchasedPlus) {
            const discountAmount = gaProducts.reduce((a, c) => c.name.match(/[0-9]+%/i), '')
            if (discountAmount) {
              Analytics.track(`Purchased Plus ${discountAmount[0]} off Product`)
            } else {
              Analytics.track('Purchased Plus')
            }
          }
          if (numFrames + numTVs + numCals + numPhotobooks + numPhotoBookPlus > 0) {
            const ShopifyObject = {
              checkout: {},
            }
            window.Shopify = ShopifyObject
            window.Shopify.checkout.order_id = orderID
            const script = document.createElement('script')
            const surveySrc = `https://app.fairing.co/shopify/survey-script.js?k=${enquireKey}&i=rc`
            script.async = true
            script.defer = true
            script.src = surveySrc
            document.querySelector('.rechargeScript').appendChild(script)
          }
          if (numFrames > 0) Analytics.track('Purchased Frame')
          if (numCals > 0) Analytics.track('Purchased Calendar')
          if (numTVs > 0) Analytics.track('Purchased TV')
          if (numPhotobooks > 0) Analytics.track('Purchased Photo Book Product')
          if (numPhotoBookPlus > 0) Analytics.track('Purchased Photo Book Plus Product')
        }, 5000)
      }
      this.setState(newState)
    }
  }

  render() {
    const { email, showPixels, shareASaleSrc, onlyPlus, anyTVs } = this.state
    const { t } = this.props

    return (
      <>
        <SEO
          title={t('Thank You')}
          description={t(
            "We're excited to help you send love via Skylight. A confirmation email with your receipt has been sent to"
          )}
          path="/pages/thank-you"
        />
        <ContentContainer>
          <Container>
            <Header>
              <Trans>Thank you for your purchase!</Trans>
            </Header>
            <Subheader>
              <Trans>Download the Skylight App Now</Trans>
            </Subheader>
            {anyTVs && (
              <>
                <SmallText>
                  <Trans>
                    You will receive instructions via email on how to set up Skylight Digital and
                    start sending photos to your TV. You can also download our mobile app and get
                    started right away!
                  </Trans>
                </SmallText>
                {onlyPlus && (
                  <AppStoreBtnContainer>
                    <CenteredBtnGroup>
                      <BadgeAnchorCtn
                        href="https://apps.apple.com/us/app/skylight-app/id1438779037"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Download on the Apple Store"
                          src="https://skylight-marketing-assets.s3.amazonaws.com/app-store-badge.png"
                          width="136"
                          height="40"
                        />
                      </BadgeAnchorCtn>
                      <BadgeAnchorCtn
                        href="https://play.google.com/store/apps/details?id=com.skylightframe.mobile&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt="Get it on Google Play"
                          src="https://skylight-marketing-assets.s3.amazonaws.com/google-play-badge.png"
                          width="136"
                          height="40"
                        />
                      </BadgeAnchorCtn>
                    </CenteredBtnGroup>
                  </AppStoreBtnContainer>
                )}
              </>
            )}
            {onlyPlus && !anyTVs && (
              <SmallText>
                <Trans>If you purchased Plus for someone else, </Trans>
                <a
                  href="https://app.ourskylight.com/share-plus-account"
                  target="_blank"
                  title="Share your Plus Account"
                  rel="noopener noreferrer"
                >
                  <Trans>please click here</Trans>
                </a>
                .
              </SmallText>
            )}
            {email && (
              <>
                <SmallText style={{ marginBottom: 0 }}>
                  <Trans
                    i18nKey="A confirmation email with your receipt has been sent to"
                    values={{ email }}
                  >
                    A confirmation email with your receipt has been sent to {email}.
                  </Trans>
                </SmallText>
                <div data-post-purchase-survey />
              </>
            )}
            {!onlyPlus && <ThankYouMobileUpsell />}
            <SmallText>
              <Trans>For any questions about your order or how to set up your Skylight, </Trans>
              <a
                className="sky-btn"
                href="https://skylight.zendesk.com/hc/en-us"
                target="_blank"
                title="Skylight Support"
                rel="noopener noreferrer"
              >
                <Trans>click here</Trans>
              </a>{' '}
              <Trans>to view our support page.</Trans>
            </SmallText>
          </Container>
        </ContentContainer>
        {showPixels && (
          <img
            alt="Share a Sale"
            id="share-a-sale-pixel"
            width="1"
            height="1"
            src={shareASaleSrc}
          />
        )}
        <div className="rechargeScript" />
      </>
    )
  }
}

ThankYou.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(ThankYou)

const Header = styled(BigHeader)`
  text-align: center;
  background-color: rgb(255, 254, 249);
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
    margin: 50px auto 1rem;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 24px auto 16px;
    font-size: 30px;
  }
`

const Subheader = styled.h2`
  text-align: center;
  margin-bottom: 2rem;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 22px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 20px;
  }
`

const Container = styled.div`
  max-width: 900px;
  margin: auto;
  text-align: center;
  font-family: 'FilsonPro';
  word-break: break-word;
  .os-order-number {
    color: ${(props) => props.theme.cream};
  }
  button {
    font-size: 16px;
  }
  .os-step__description {
    padding-bottom: 20px;
  }
  #survey_response_other_text {
    color: rgb(152, 137, 128);
    font-family: 'FilsonPro';
    font-size: 16px;
    line-height: 1.625;
    font-weight: normal;
  }
  .content-box {
    margin: 20px 0px;
    padding: 20px 15%;
    @media (max-width: ${breakpoints.s}px) {
      padding: 10px 5%;
    }
  }
  #enquire-branding-tag {
    display: none;
  }
  .enquire__success-message {
    margin: auto;
  }
  .btn--size-small {
    background-color: ${(props) => props.theme.coral};
    color: white;
    text-decoration: none;
    border-radius: 28px;
    border: none;
    font-size: 14px;
    padding: 15px 30px;
    line-height: 1.625;
    &:hover {
      cursor: pointer;
      background-color: ${(props) => darken(0.1, props.theme.coral)};
    }
    &:focus {
      outline: none;
      box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.coral)}`};
    }
  }
`
const CenteredBtnGroup = styled(BtnGroup)`
  justify-content: center;
`
export const AppStoreBtnContainer = styled.div`
  margin: 20px 0;
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "thank-you"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
