import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 2.5rem 0;
`

export const Text = styled.p`
  margin: 0 0 0.5rem;
`

export const BadgeAnchorCtn = styled.a``

export const BtnGroup = styled.section`
  display: flex;
  margin-top: 1rem;
  a:not(:first-child) {
    margin-left: 1rem;
  }
`
