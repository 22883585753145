import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'

import { BadgeAnchorCtn, BtnGroup, Container, Text } from './styles'

const ThankYouMobileUpsell = () => (
  <Container>
    <Text>
      <b>
        <Trans>Check out our mobile app and start pre-loading your frame with photos.</Trans>
      </b>
    </Text>
    <BtnGroup>
      <BadgeAnchorCtn
        href="https://apps.apple.com/us/app/skylight-app/id1438779037"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="Download on the Apple Store"
          src="https://skylight-marketing-assets.s3.amazonaws.com/app-store-badge.png"
          width="136"
          height="40"
        />
      </BadgeAnchorCtn>
      <BadgeAnchorCtn
        href="https://play.google.com/store/apps/details?id=com.skylightframe.mobile&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="Get it on Google Play"
          src="https://skylight-marketing-assets.s3.amazonaws.com/google-play-badge.png"
          width="136"
          height="40"
        />
      </BadgeAnchorCtn>
    </BtnGroup>
  </Container>
)

ThankYouMobileUpsell.propTypes = {}

export default ThankYouMobileUpsell
